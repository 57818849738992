<template>
  <ymap-marker
    v-if="$types.length"
    :marker-id="id"
    :coords="coords"
    @balloonopen="bindListener"
    @balloonclose="unbindListener"
    @click="initPopup"
    :icon="markerSymbol()"
  >
    <PopUp
      slot="balloon"
      ref="placePopup"
      :id="id"
      :title="title"
      :audio="audio"
      :image="image"
      service="places"
      :address="address"
      :playing="playing"
    ></PopUp>
  </ymap-marker>
</template>

<script>
import PopUp from './PopUp.vue';

export default {
  name: 'OnMapPlace',
  components: {
    PopUp,
  },
  props: {
    coords: {
      type: Array,
      required: true,
    },
    address: String,
    audio: String,
    image: {
      type: String,
    },
    title: {},
    name: {},
    id: {},
    number: {},
    icon: {
      type: String,
      default: '',
    },
    setting_icon: null,
  },
  data() {
    return {
      playing: false,
      volume: 100,
      audioEl: undefined,
      playIcon:
        '<svg viewBox="0 0 20 20" class="icon player__button-icon" style="width: 14px; height: 14px;"><use xlink:href="#play"></use></svg>',
      pauseIcon:
        '<svg viewBox="0 0 20 20" class="icon player__button-icon" style="width: 14px; height: 14px;"><use xlink:href="#pause"></use></svg>',
    };
  },
  methods: {
    initPopup() {
      this.$refs.placePopup.init();
      this.$emit('activate-place', { id: this.id, title: this.title });
    },
    markerSymbol() {
      let size = [44, 54];
      let imageHref = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDciIGhlaWdodD0iNDYiIHZpZXdCb3g9IjAgMCA0NyA0NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMy42NTUzIDQwQzM0LjEzNDMgNDAgNDIuNjI5MyAzMS40OTM0IDQyLjYyOTMgMjFDNDIuNjI5MyAxMC41MDY2IDM0LjEzNDMgMiAyMy42NTUzIDJDMTMuMTc2MiAyIDQuNjgxMjYgMTAuNTA2NiA0LjY4MTI2IDIxQzQuNjgxMjYgMzEuNDkzNCAxMy4xNzYyIDQwIDIzLjY1NTMgNDBaIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSI4LjY3NTc4IiB5PSI2IiB3aWR0aD0iMjkuOTU5IiBoZWlnaHQ9IjMwIiByeD0iMTQuOTc5NSIgZmlsbD0iIzAwQTM2NyIvPgo8L3N2Zz4=';

      if (this.setting_icon) {
        size = [parseInt(this.setting_icon.width, 10), parseInt(this.setting_icon.height, 10)];
      }
      if (this.icon) {
        imageHref = this.icon;
      }

      return {
        layout: this.number !== null ? 'default#imageWithContent' : 'default#image',
        imageHref,
        imageSize: size,
        imageOffset: size.map(value => -value / 2),
      };
      // if (this.icon) {
      //   marker.imageHref = this.icon;
      // }
      // if (this.icon && this.icon.indexOf('.svg') + 1) { // временно отключил
      //   const div = document.createElement('div');
      //   const currentSvg = this.$typesSVG.find(svg => svg.url === this.icon);
      //   div.innerHTML = currentSvg ? currentSvg.img : '';
      //   div.getElementsByClassName('color-marker-symbol').forEach((el) => {
      //     const element = el;
      //     element.style.fill = this.setting_icon.color ? this.setting_icon.color : '#000000';
      //     element.style.opacity = this.setting_icon.transparency || 1;
      //   });
      //   const svgElement = div.getElementsByTagName('svg');
      //   if (svgElement.length > 0) {
      //     const s = new XMLSerializer().serializeToString(svgElement[0]);
      //     marker.imageHref = `data:image/svg+xml;base64,${window.btoa(s)}`;
      //   }
      // }
      // if (this.number !== null) {
      //   marker.contentLayout = `<span style="color: #000000; font-weight: bold;">${
      //     this.number + 1
      //   }</span>`;
      //   marker.imageSize = [40, 40];
      //   marker.contentOffset = [15, 10]; // [15, -16]; // [35, -10];
      //   marker.content = 'content';
      // }
      // return marker;
    },
    bindListener() {
      if (this.audio) {
        const mapPlayerButton = document.getElementById('popup-on-map-player-button');
        if (mapPlayerButton) {
          mapPlayerButton.addEventListener('click', this.handler);
        }
      }
    },
    unbindListener() {
      const mapPlayerButton = document.getElementById('popup-on-map-player-button');
      if (mapPlayerButton) {
        mapPlayerButton.removeEventListener('click', this.handler);
      }
    },
    handler() {
      this.playing = !this.playing;
      const button = document.getElementById('popup-on-map-player-button');
      button.classList.toggle('player__button-playing');
      button.style.backgroundColor = this.playing ? '#232120' : '';
      button.removeChild(button.firstChild);
      button.insertAdjacentHTML('afterbegin', this.playing ? this.pauseIcon : this.playIcon);
      const audioEl = button.getElementsByTagName('audio')[0];
      if (this.playing) {
        audioEl.play();
      } else {
        audioEl.pause();
      }
    },
  },
};
</script>
