<template>
  <div :class="'popup-' + id">
    <a :href="placeUrl">
      <img ref="image" v-if="Boolean(image)" :data-src="image" style="width: 100%; height: 75%"/>
      <div style="padding-top: 5px"><h3>{{title}}</h3></div>
    </a>
    <div style="display: flex;">
      <span style="margin-top: 10px; color: rgba(0, 0, 0, 0.5);font-size: 14px;flex: 1">
        {{address}}
      </span>
      <div
        v-if="Boolean(audio)"
        id="popup-on-map-player-button"
        class="player__button"
        :class="{'player__button-playing': playing}"
        style="width: 32px; height: 32px">
        <svg viewBox="0 0 20 20"
             class="icon player__button-icon"
             style="width: 14px; height: 14px;">
          <use xlink:href="#play"></use>
        </svg>
        <audio ref="audiofile" preload="none">
          <source :src="audio" type="audio/ogg"/>
          <source :src="audio" type="audio/mp3"/>
        </audio>
      </div>
    </div>
    <div>
      <button
        class="button button__green button__reviews"
        style="margin-top: 7px; padding: 10px 15px"
        onclick="window.vue.$store.commit('SHOW_YANDEX_WIDGET', true)"
        v-if="id != 1429"
      >
        Отзывы
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopUp',

  props: {
    id: null,
    image: {
      type: String,
      default: '',
    },
    audio: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    playing: {
      type: Boolean,
      default: false,
    },
    address: {
      type: String,
      default: '',
    },
    service: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      volume: 100,
      audioEl: undefined,
    };
  },

  methods: {
    init() {
      if (this.$refs.image.hasAttribute('data-src')) {
        this.$refs.image.src = this.$refs.image.dataset.src;
      }
    },
  },

  computed: {
    placeUrl: vm => `/${vm.service}/${vm.id}`,
  },
};
</script>
