<template>
  <div id="map" :style="{ width, height }">
    <yandex-map
        ref="yandexMap"
        :controls="['geolocationControl', 'zoomControl']"
        :detailed-controls="{ geolocationControl: { position: { top: 10, right: 10 } } }"
        :options="{ suppressMapOpenBlock: true }"
        @map-was-initialized="initMapHandler"
        :coords="location || coords"
        :zoom="zoom"
        :settings="settings"
        :style="{ width: '100%', height: '100%' }"
    >
      <on-map-place
        v-for="(place, index) in places"
        :key="place.id"
        :number="useNumber ? index : null"
        :id="place.id"
        :coords="place.location"
        :image="getImage(place.images)"
        :address="place.address"
        :audio="place.audios && place.audios.length ? place.audios[0] : ''"
        :icon="place.type ? place.type.image : null"
        :setting_icon="place.type && place.type.icon_settings"
        :title="place.title || place.name"
        @activate-place="activatePlace"
      />
    </yandex-map>

    <transition name="fade">
      <div class="yandex-widget-wrapper" v-show="$yandexWidgetVisible">
        <button
          class="yandex-widget-wrapper__button-close"
          @click="$store.commit('SHOW_YANDEX_WIDGET', false)"
        >
          &times;
        </button>
        <YandexComments ref="yandexWidget" />
      </div>
    </transition>
  </div>
</template>

<script>
import OnMapPlace from './OnMapPlace.vue';

export default {
  name: 'OnMap',

  components: {
    OnMapPlace,
  },

  props: {
    isPedestrian: {
      default: false,
    }, // пешеходный маршрут
    params: {},
    location: null,
    places: {},
    events: {},
    useNumber: {
      default: false,
    },
  },

  data() {
    return {
      coords: [56.48464, 84.947649],
      zoom: 13,
      settings: {
        apiKey: '3ce74472-614d-48ae-be3b-43d1739a0368',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1',
      },
    };
  },

  computed: {
    width() {
      return this.params && this.params.sizeMap && this.params.sizeMap.width
        ? this.params.sizeMap.width
        : '100%';
    },
    height() {
      return this.params && this.params.sizeMap && this.params.sizeMap.height
        ? this.params.sizeMap.height
        : '100%';
    },
  },
  created() {
    this.coords = this.params && this.params.coords ? this.params.coords : this.coords; // Томск
    this.zoom = this.params && this.params.zoom ? this.params.zoom : this.zoom; // Томск
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    params() {
      this.coords = this.params && this.params.coords ? this.params.coords : this.coords;
    },
  },

  methods: {
    async activatePlace(data) {
      const { id, title } = data;
      const companies = await this.$getYandexCompaniesByTitle(title);

      if (companies.length) {
        this.$refs.yandexWidget.setCommentsTarget(companies.shift());
      } else {
        const currentPopupButton = document.querySelector(`.popup-${id} .button__reviews`);
        if (currentPopupButton) {
          currentPopupButton.classList.add('button__disabled');
        }
      }
    },
    getImage(images) {
      return Array.isArray(images) && images[0] ? images[0] : '';
    },
    initMapHandler(Map) {
      // пешеходный маршрут
      if (this.isPedestrian) {
        const geoArr = this.places.map(It => It.location);
        // eslint-disable-next-line no-undef
        const multiRoute = new ymaps.multiRouter.MultiRoute(
          {
            referencePoints: geoArr,
            params: {
              routingMode: 'pedestrian', // пешком
            },
          },
          {
            // скрыть иконки и balloon
            wayPointVisible: false,
            balloonLayout: false,
            // Автоматом границы карты, чтобы маршрут весь целиком
            boundsAutoApply: true,
          },
        );
        Map.geoObjects.add(multiRoute);
      }
    },
  },
};
</script>

<style lang="scss">
  #map {
    .yandex-widget-wrapper {
      position: fixed;
      right: 10px;
      bottom: 32px;
      max-width: 100%;
      max-height: 66vh;

      &__button-close {
        position: absolute;
        right: 5px;
        top: -20px;
        font-size: 25px;
        padding: 0 8px;
        cursor: pointer;
      }
    }
  }
</style>
